import React from 'react';

interface Props {
    text: string;
    wordLimit: number;
}

const TruncatedText: React.FC<Props> = ({ text, wordLimit }) => {
    const truncateText = (text: string, wordLimit: number): string => {
        const words = text; 

        if (words.length > wordLimit) {
            return words.slice(0, wordLimit) + '...';
        }
        return text;
    };
    return  truncateText(text, wordLimit)
};
export default TruncatedText;
