import React from 'react';
import AppRouter from './AppRouter';
import NavBar from "./component/NavBar/NavBar";
import './App.css';
const App: React.FC = () => {
  return (
      <div>
       
        <AppRouter />
      </div>
  );
};

export default App;
