import React, {useEffect, useState} from 'react';
import './SliderImges.css';

interface Image {
    name: string;
    img: string;
}

interface SliderProps {
    images: Image[];
}

const Slider: React.FC<SliderProps> = ({images}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const delayUsingSetTimeout = (callback: () => void, delay: number): void => {
        setTimeout(callback, delay);
    };

    const handleNext = () => {
        document.getElementById('license-img')?.style.setProperty('opacity', '0');
        delayUsingSetTimeout(() => {
        }, 800);
        delayUsingSetTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
            setSelectValue((currentIndex + 1 < images.length) ? currentIndex + 1 : (currentIndex === images.length - 1) ? 0 : currentIndex);
            document.getElementById('license-img')?.style.setProperty('opacity', '1');
        }, 1000);

    };

    const handlePrevious = () => {
        document.getElementById('license-img')?.style.setProperty('opacity', '0');
        delayUsingSetTimeout(() => {
        }, 800);
        delayUsingSetTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
            setSelectValue((currentIndex - 1 >= 0) ? currentIndex - 1 : (currentIndex === 0) ? images.length - 1 : currentIndex);
            document.getElementById('license-img')?.style.setProperty('opacity', '1');
        }, 1000);

    };
    const handleNavigationClick = (index: number) => {
        document.getElementById('license-img')?.style.setProperty('opacity', '0');
        delayUsingSetTimeout(() => {
        }, 800);
        delayUsingSetTimeout(() => {
            setCurrentIndex(index);
            setSelectValue(index);
            document.getElementById('license-img')?.style.setProperty('opacity', '1');
        }, 1000);

    };
    const setSelectValue = (index: number) => {
        const select = document.getElementById(`select-${index}`);
        const selected = document.querySelectorAll('option[selected]');
        selected.forEach((option) => {
            option.removeAttribute('selected');
        });
        if (select) {
            select.setAttribute('selected', 'true');
        }
    }
    const handleImageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index = parseInt(event.target.value);
        handleNavigationClick(index);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            handleNext();
        }, 3000); // Transition to next slide every 3 seconds

        return () => {
            clearTimeout(timer); // Clear the timer when the component unmounts or currentIndex changes
        };
    }, [currentIndex]);
    return (
        <div className={'slider-img'}>
            <div className={"group-slider"}>
                <img id={'license-img'} src={require(`../../../src/images/${images[currentIndex].img}`)}
                     alt={images[currentIndex].name}/>
            </div>
            {/*<span>{images[currentIndex].name}</span>*/}

            <div className={"nav-images"}>
                {images.map((image, index) => (
                    <div key={index} onClick={() => handleNavigationClick(index)}
                         className={currentIndex === index ? 'active' : ''} id={"slider-nav-" + String(index)}>
                    </div>
                ))}
            </div>
            <select className={"selection-slider"} onChange={handleImageChange}>
                <option value="" disabled={true}>Select an image</option>
                {images.map((image, index) => (
                    <option key={index} value={String(index)} id={"select-" + String(index)}>
                        {image.name}
                    </option>
                ))}
            </select>
            <div className={"group-slider-btn"}>
                <button className={"slider-btn left"} onClick={handlePrevious}>
                    <svg viewBox="0 0 6 9" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.03651 4.91425L1.87659 8.22822C1.65818 8.45727 1.30502 8.45727 1.08893 8.22822L0.563829 7.67751C0.345423 7.44846 0.345423 7.07808 0.563829 6.85146L2.80365 4.50244L0.563829 2.15343C0.345423 1.92437 0.345423 1.55399 0.563829 1.32737L1.08661 0.771796C1.30502 0.542743 1.65818 0.542743 1.87427 0.771796L5.03419 4.08576C5.25492 4.31481 5.25492 4.6852 5.03651 4.91425Z"/>
                    </svg>
                </button>
                <button className={"slider-btn right"} onClick={handleNext}>
                    <svg viewBox="0 0 6 9" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.03651 4.91425L1.87659 8.22822C1.65818 8.45727 1.30502 8.45727 1.08893 8.22822L0.563829 7.67751C0.345423 7.44846 0.345423 7.07808 0.563829 6.85146L2.80365 4.50244L0.563829 2.15343C0.345423 1.92437 0.345423 1.55399 0.563829 1.32737L1.08661 0.771796C1.30502 0.542743 1.65818 0.542743 1.87427 0.771796L5.03419 4.08576C5.25492 4.31481 5.25492 4.6852 5.03651 4.91425Z"/>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Slider;
