import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './page/home/Home';

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<Home />} />
                {/* <Route path="/:lang" element={<Home />} /> */}
            </Routes>
        </Router>
    );
};

export default AppRouter;
