import React from 'react';
import './styles.css';
import jsonData from '../../../src/data/services.json';
import TruncatedText from "../../component/TruncatedText";
import Slider from "../../component/Slider/SliderImages";
import license from '../../../src/data/license.json';
import FacebookPosts from "../../component/Post/Post";
import Map from "../../component/Map/Map";
import { useLocation } from 'react-router-dom';
import NavBar from "../../component/NavBar/NavBar";
import ImageSlider from '../../component/ImageSlider/ImageSlider';
const Home: React.FC = () => {
    const services = jsonData;
    const location = useLocation();
    if (location.pathname === "/") {
    }
    const [language, setlanguage] = React.useState("en");

    return (
        <div>
            <NavBar lang={language} setLang={setlanguage} />
            {/* <div className={"admin-connect"}>
                <svg width="45" height="45" viewBox="0 0 50 50" fill="black" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M42.223 10.34C35.5451 5.32 26.6692 4.17735 19.296 5.69134C11.0469 -3.36102 1.80295 0.802713 0 1.99969C0 1.99969 6.34374 8.1327 5.31336 13.5043C-2.19704 22.2149 1.37326 31.9132 5.31336 36.4927C6.34374 41.8642 0 47.9972 0 47.9972C1.78559 49.1982 11.0043 53.3491 19.296 44.3431C26.6527 45.8472 35.5286 44.7145 42.223 39.6856C52.5754 32.166 52.6093 17.8981 42.223 10.339V10.34ZM25.5364 39.6372C22.9547 39.6456 20.3832 39.2681 17.888 38.5143L16.1718 40.3996C15.2186 41.4514 14.1512 42.3604 12.9956 43.1047C11.5936 43.9078 10.0705 44.4014 8.50693 44.5594C8.59373 44.3836 8.67012 44.2078 8.74738 44.0419C10.4644 40.4207 10.9273 37.1718 10.1363 34.2953C7.31248 31.766 5.62238 28.5336 5.62238 24.999C5.62238 16.9006 14.5408 10.34 25.5364 10.34C36.532 10.34 45.4504 16.9006 45.4504 24.999C45.4504 33.0973 36.532 39.6372 25.5364 39.6372ZM15.9826 28.4852C15.1942 28.4979 14.4336 28.1545 13.8674 27.5303C13.3011 26.906 12.9755 26.0519 12.9618 25.155C12.901 20.6723 18.8498 20.5745 18.9097 25.0474V25.0977C18.9133 25.539 18.8405 25.9768 18.6954 26.386C18.5502 26.7953 18.3355 27.168 18.0637 27.4828C17.7918 27.7976 17.4681 28.0483 17.111 28.2206C16.7539 28.393 16.3705 28.4836 15.9826 28.4872V28.4852ZM22.3862 25.155C22.3177 20.6723 28.2664 20.5646 28.335 25.0375V25.0977C28.3689 29.5498 22.4548 29.5982 22.3862 25.155ZM34.8324 28.4852C34.0439 28.4979 33.2832 28.1545 32.7168 27.5303C32.1505 26.9061 31.8246 26.052 31.8107 25.155C31.7508 20.6723 37.6996 20.5745 37.7595 25.0474V25.0977C37.7644 25.5394 37.6924 25.9778 37.5477 26.3877C37.403 26.7976 37.1884 27.1709 36.9163 27.4859C36.6442 27.801 36.32 28.0517 35.9623 28.2236C35.6047 28.3954 35.2206 28.485 34.8324 28.4872V28.4852Z" />
                </svg>
            </div> */}
            <div className={"div-header"} id={"home"}>
                <div className={"video-bg"} style={{
                    backgroundImage: `url(${require('./bg.png')
                        })`
                }}>
                    <video src={require('./bg_video.mp4')} autoPlay muted loop></video>
                </div>
                <div className={"div-header-content"}>
                    <h1 className={"header-name"}>Est Serend</h1>
                    <h1 className={"header-name"}>Engineering</h1>
                    <b>Crane Inspection and Load Test</b>
                    <hr></hr>
                    <span>Est Serend Engineering Co.,Ltd. has been obtained<br></br>Engineering License of Juristic Person No. นต.2041</span>
                </div>
            </div>
            <div className={"div-service"} id={"service"}>
                <div className={"title-div"}>
                    <h2>
                        Services
                    </h2>
                    <hr></hr>
                </div>
                <div className="div-service-content">
                    <div className="div-services">
                        {services.map((service, index) => (
                            <div className="service" key={index}>
                                <div className="service-img">
                                    <ImageSlider images={Array.isArray(service.img) ? service.img : [service.img]} />
                                    {/* <img src={require(`../../../src/images/${Array.isArray(service.img) ? service.img[0] : service.img}`)}
                                        alt={service.title.en} /> */}
                                </div>
                                <span className="title">{service.title[language as keyof typeof service.title].split("_")[0]}</span>
                                <span className='subtitle'>{service.title[language as keyof typeof service.title].split("_")[1]}</span>
                                {service.details && (
                                    <div className="paragraph">
                                        {
                                            service.details.map((detail, detailIndex) => (
                                                <p key={detailIndex}>
                                                    - <TruncatedText text={detail[language as keyof typeof detail]} wordLimit={500} />
                                                </p>
                                            ))
                                        }
                                    </div>
                                )}

                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={"div-about"} id={"about"}>
                <div className={"about-content"}>
                    <div className={"content-in-about"}>
                        <div className={"title-div"}>
                            <h2>
                                About
                            </h2>
                            <hr></hr>
                        </div>
                        {language === 'th' ? <p>
                            บริษัท เอสท์ เซเรนด์ เอ็นจิเนียริ่ง จำกัด เป็นบริษัทที่ให้บริการตรวจสอบเครื่องจักรกล
                            โดยมีทีมงานผู้เชี่ยวชาญที่มีประสบการณ์และความเชี่ยวชาญในการตรวจสอบเครื่องจักรกลทุกรูปแบบ
                            ซึ่งเรามุ่งมั่นที่จะให้บริการที่มีคุณภาพและประสิทธิภาพสูงสุดให้กับลูกค้าของเรา
                            เรามีเครื่องมือและอุปกรณ์ที่ทันสมัยและผ่านการสอบเทียบตามมาตราฐาน ISO/IEC 17025. เพื่อให้การตรวจสอบเครื่องจักรกลเป็นไปอย่างมีประสิทธิภาพ
                            และเรายังมีการอบรมและพัฒนาทีมงานอยู่เสมอ
                            เพื่อให้เขามีความรู้ความเชี่ยวชาญที่เพียงพอในการให้บริการตรวจสอบเครื่องจักรกลให้กับลูกค้าของเรา
                            เรามุ่งหวังที่จะเป็นผู้นำในการให้บริการตรวจสอบเครื่องจักรกลในประเทศไทย
                            และเราจะพัฒนาต่อยอดเพื่อให้บริการที่มีคุณภาพและประสิทธิภาพสูงสุดให้กับลูกค้าของเราตลอดเวลา
                        </p> : <p>
                            Est Serend Engineering Co., Ltd., is a company that provides machinery inspection services.
                            Our team of experienced and qualified experts is specialized in inspecting all types of machinery.
                            We are committed to providing our customers with the highest quality and most efficient machinery inspection services possible.
                            We utilize modern, ISO/IEC 17025 calibrated tools and equipment to ensure efficient inspections, and continuously train and develop our team to maintain their expertise.
                            Our goal is to be a leading provider of machinery inspection services in Thailand,
                            and we are committed to continuous development to ensure the highest quality and efficiency in serving our customers.
                        </p>}
                    </div>
                </div>
                <div className={"about-group-img"}>
                    <div className={"about-img"}>
                        {/* <AboutImage imageUrls={["about1.jpg", "about2.jpg", "about3.jpg", "about4.jpg"]} altText={"about"} /> */}
                        <img className={"about-bg"} src={require(`../../../src/images/about.jpg`)} alt={"about"} />
                        <img className={"about-logo"} src={require(`../../../src/images/icon.png`)} alt={"about-logo"} />
                    </div>
                </div>
            </div>
            <div className={"div-status"} id={"status"}>
                <div className={"card-status"}>
                    <h1>2020</h1>
                    <span>Year<br></br>Established</span>
                </div>
                <div className={"card-status"}>
                    <h1>1000+</h1>
                    <span>Projects<br></br>Completed</span>
                </div>
                <div className={"card-status"}>
                    <h1>30+</h1>
                    <span>Served<br></br>Customers</span>
                </div>
            </div>
            <div className={"div-license"} id={"license"}>
                <div className={"title-div"}>
                    <h2>
                        License
                    </h2>
                    <hr></hr>
                </div>
                <div className={"slider-img-license"}>
                    <Slider images={license} />
                </div>
            </div>
            <div className={"div-project"} id={"project"}>
                <div className={"title-div"}>
                    <h2>
                        Projects
                    </h2>
                    <hr></hr>
                </div>

                <FacebookPosts />
            </div>
            <div className={"div-contact"} id={"contact"}>
                <div className={"title-div"}>
                    <h2>
                        Contact
                    </h2>
                    <hr></hr>
                </div>
                <div className={"contact-content"}>
                    <div className={"contact-map"}>
                        <Map />
                    </div>
                </div>
                <div className={"contact-address"}>
                    <div className={"div-grid"}>
                        <div className={"grid-element"}>
                            <h2>Inquiries</h2>
                            <p>
                                {language === "th" ? "หากต้องการสอบถามหรือข้อเสนอแนะ" : "For inquiries or suggestions,"}<br></br>
                                {language === "th" ? "โปรดโทร" : "please call"} : <a href="tel:086-354-6087">086 354 6087</a>
                            </p>
                        </div>
                        <div className={"grid-element"}>
                            <h2>Head Office</h2>
                            <p>
                                {language === "th" ? "จันทร์ - เสาร์" : "Mon - Sat"} : 08:00 - 17:00<br></br>
                            </p>
                            <p>
                                134/147 {language === "th" ? "หมู่ 6 ตำบลพลูตาหลวง" : "Moo.6, Tambol Plutaluang,"}<br></br>{language === "th" ? "อำเภอสัตหีบ จังหวัดชลบุรี" : "Ampher Sattahip, Chonburi"} 20180
                            </p>
                            <p>
                                {language === "th" ? "อีเมล" : "Email"} : <a href="mailto: sales@estserend.com">sales@estserend.com</a>
                                <br></br>{language === "th" ? "โทร" : "Tel"} :<a href="tel:086-354-6087"> 086 354 6087</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// @ts-ignore
export default Home;
