import React from 'react';
import './styles.css';
import logo from './logo.jpg';

const smoothScroll = (id: string) => {
    const target = document.getElementById(id);
    if (!target) return;
    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - 90;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime: number | null = null;
    const animation = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, 1000);
        window.scrollTo(0, run);
        if (timeElapsed < 1000) requestAnimationFrame(animation);
    };
    const ease = (t: number, b: number, c: number, d: number): number => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    requestAnimationFrame(animation);
};
interface props {
    lang: string;
    setLang: (lang: string) => void;
}
const Navbar: React.FC<props> = ({ lang, setLang }) => {
    const toggleMenu = () => {
        const menu = document.querySelector('.navbar__menu');
        
        menu?.classList.toggle('active');
    };
    const handleLanguage = (value: string) => {
        setLang(value);
    }

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            const startPosition = window.pageYOffset;
            const home = document.getElementById('home');
            const service = document.getElementById('service');
            const about = document.getElementById('about');
            const license = document.getElementById('license');
            const project = document.getElementById('project');
            const contact = document.getElementById('contact');
            const allTargets = document.querySelectorAll('.navbar__menu ul li');
            allTargets.forEach((t) => {
                t.classList.remove('menu-active');
            });
            if (home && service && about && license && project && contact) {
                if (startPosition < service.getBoundingClientRect().top + window.pageYOffset - 100) {
                    const targetNav = document.getElementById('nav-home');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                } else if (startPosition < about.getBoundingClientRect().top + window.pageYOffset - 100) {
                    const targetNav = document.getElementById('nav-service');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                } else if (startPosition < license.getBoundingClientRect().top + window.pageYOffset - 100) {
                    const targetNav = document.getElementById('nav-about');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                } else if (startPosition < project.getBoundingClientRect().top + window.pageYOffset - 100) {
                    const targetNav = document.getElementById('nav-license');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                } else if (startPosition < contact.getBoundingClientRect().top + window.pageYOffset - 100) {
                    const targetNav = document.getElementById('nav-project');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                } else {
                    const targetNav = document.getElementById('nav-contact');
                    if (targetNav) {
                        targetNav.classList.add('menu-active');
                    }
                }
            }
        })
    }, []);
    return (
        <div className="navbar">
            <a href={"/"}>
                <div className="navbar__logo">
                    <img className="logo-img" src={logo} alt="logo" />
                    <div className="text-logo">
                        <span className="Est-logo">Est Serend</span>
                        <span className="Est-sub-logo">Engineering</span>
                    </div>
                </div>
            </a>
            <div className="navbar__toggle" onClick={toggleMenu}>☰</div>
            <div className="navbar__menu">
                <ul>
                    <li id={"nav-home"} onClick={() => smoothScroll("home")}>Home</li>
                    <li id={"nav-service"} onClick={() => smoothScroll("service")}>Services</li>
                    <li id={"nav-about"} onClick={() => smoothScroll("about")}>About</li>
                    <li id={"nav-license"} onClick={() => smoothScroll("license")}>license</li>
                    <li id={"nav-project"} onClick={() => smoothScroll("project")}>Projects</li>
                    <li id={"nav-contact"} onClick={() => smoothScroll("contact")}>Contact</li>
                </ul>
                {/* <div className={"user-section"}>
                    {isLoggedIn ? (
                        <div className={"user-section__logged-in"}>
                            <span>Logged in as: </span>
                            <span>John Doe</span>
                        </div>
                    ) : (
                        <div className={"user-section__logged-out"}>
                            <div className={"btn-login"}>
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.5 0.5C7.66992 0.5 0.515625 7.4375 0.515625 16C0.515625 24.5625 7.66992 31.5 16.5 31.5C25.3301 31.5 32.4844 24.5625 32.4844 16C32.4844 7.4375 25.3301 0.5 16.5 0.5ZM16.5 6.5C19.6324 6.5 22.1719 8.9625 22.1719 12C22.1719 15.0375 19.6324 17.5 16.5 17.5C13.3676 17.5 10.8281 15.0375 10.8281 12C10.8281 8.9625 13.3676 6.5 16.5 6.5ZM16.5 28C12.7166 28 9.32637 26.3375 7.05762 23.7375C8.26934 21.525 10.6412 20 13.4062 20C13.5609 20 13.7156 20.025 13.8639 20.0688C14.7018 20.3313 15.5783 20.5 16.5 20.5C17.4217 20.5 18.3047 20.3313 19.1361 20.0688C19.2844 20.025 19.4391 20 19.5938 20C22.3588 20 24.7307 21.525 25.9424 23.7375C23.6736 26.3375 20.2834 28 16.5 28Z" />
                                </svg>
                                <span>Login</span>
                            </div>
                            <div className={"register"}>Get&nbsp;started</div>
                        </div>
                    )}
                </div> */}
                <div className='change-lang'>
                    <select name="language" id="language" onChange={(e) => handleLanguage(e.target.value)} value={lang}>
                        <option value="en">English</option>
                        <option value="th">Thai</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
export default Navbar;
