import React, { useEffect, useState } from 'react';
import './ImageSlider.css';
type ImageSliderProps = {
    images: string[];
};

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // make to auto next
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);
        return () => clearInterval(interval);
    }, [currentIndex, images.length]);

    return (
        <div style={{ textAlign: 'center' }} className='image-container'>
            <img
                src={require(`../../../src/images/${images[currentIndex]}`)}
                about={`image ${currentIndex}`}
                alt={`Slide ${currentIndex}`}
                style={{ objectFit: 'cover' }}
            />
            {images.length > 1 && <div className='image-nav'>
                <button onClick={handlePrev}>{"<"}</button>
                <button onClick={handleNext}>{">"}</button>
            </div>}
        </div>
    );
};

export default ImageSlider;
