import React from 'react';
import './Map.css';

const Map: React.FC = () => {

    return (
        <div className="map-content">
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe className="gmap_iframe"
                            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=สัตหีบ&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </div>
        </div>
    );
};
export default Map;
